import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SwUpdate } from '@angular/service-worker';
import { DialogService } from '@salary/common/dialog';
import { LogService } from '@salary/common/logger';
import { SALARY_IS_STABLE } from '@salary/common/utils';
import { concat, delay, filter, interval, switchMap, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppUpdateService {
  private isStable$ = inject(SALARY_IS_STABLE);
  private updates = inject(SwUpdate);
  private dialogService = inject(DialogService);
  private logService = inject(LogService);
  private destroyRef = inject(DestroyRef);

  public checkForUpdates(): void {
    console.log('App-Updates enabled: ' + this.updates.isEnabled);
    if (this.updates.isEnabled) {
      this.updates.versionUpdates
        .pipe(
          tap((evt) => {
            console.log('App-Update: ' + evt.type);
            console.log(evt);
          }),
          filter((evt) => evt.type === 'VERSION_READY'),
          delay(5000),
          switchMap(() => this.promptUser()),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          this.updates.activateUpdate().then(() => document.location.reload());
        });
      concat(this.isStable$, interval(30 * 60000))
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.updates.checkForUpdate().then(() => {
            console.log('App-Update: check for updates');
            this.logService.log('checking for updates');
          });
        });
    }
  }

  private promptUser() {
    return this.dialogService
      .openDialog({
        title: 'Neue Programmversion verfügbar',
        message: `Es liegt eine neue Programmversion vor.
      Es wird dringend empfohlen sofort zu aktualisieren.
      Wenn sie sich für "Aktualisieren" entscheiden gehen ungespeicherte Änderungen verloren.`,
        button1Caption: 'Aktualisieren',
        button2Caption: 'Abbrechen',
      })
      .afterClosed()
      .pipe(filter((result) => result === 'button1'));
  }
}
