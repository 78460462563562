import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { DialogService } from '@salary/common/dialog';
import { filter } from 'rxjs';

@Injectable()
export class ChunkLoadErrorHandlerService implements IErrorService {
  private dialog = inject(DialogService);
  private destroyRef = inject(DestroyRef);
  constructor() {
    window.addEventListener('unhandledrejection', (e) => {
      this.handleError(e.reason); //https://github.com/angular/angular/issues/56240
    });
  }
  handleError(error: Error): void {
    const chunkFailedMessage = /Failed to fetch dynamically imported module/;
    if (chunkFailedMessage.test(error.message)) {
      this.dialog
        .openDialog({
          message:
            'Wir haben ein Problem festgestellt. Die Seite muss neu geladen werden.',
          button1Caption: 'Neuladen',
          button2Caption: 'Abbrechen',
        })
        .afterClosed()
        .pipe(
          filter((result) => result === 'button1'),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => window.location.reload());
    }
  }
}
