import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Renderer2,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { AnimationService, LoadingPageService } from '@salary/common/component';
import { DialogService } from '@salary/common/dialog';
import {
  AppSettingsFacade,
  LohnkontextFacade,
  SettingsFacade,
} from '@salary/common/facade';
import { isBrowserSupported } from '@salary/common/utils';
import { Settings } from 'luxon';
import { take } from 'rxjs';
import {
  loadingAnimation,
  routeTransitionAnimations,
} from './app-component.animations';
import {
  AppUpdateService,
  AzureSalaryMonitoringService,
  ChunkLoadErrorHandlerService,
} from './services';
@Component({
  selector: 'salary-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [loadingAnimation, routeTransitionAnimations],
})
export class AppComponent {
  private routingAnimationState:
    | 'list1'
    | 'list2'
    | 'detail1'
    | 'detail2'
    | 'void';
  private outletComponentChanged = false;
  private readonly agGridLicenseKey =
    'Using_this_{AG_Grid}_Enterprise_key_{AG-061319}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{BRZ_Deutschland_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{BRZ_365}_only_for_{6}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{BRZ_365}_need_to_be_licensed___{BRZ_365}_has_been_granted_a_Deployment_License_Add-on_for_{6}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{20_April_2025}____[v3]_[01]_MTc0NTEwMzYwMDAwMA==21b57ee0c9fff1b906610f43d293efa1';

  private renderer2 = inject(Renderer2);
  private loadingPageService = inject(LoadingPageService);
  private dialogService = inject(DialogService);
  private appSettingsFacade = inject(AppSettingsFacade);
  private animationService = inject(AnimationService);
  private destroyRef = inject(DestroyRef);

  protected disableExpandCollapse = signal(false);
  protected loadingState = signal<'void' | 'enter'>('void');
  protected isLeftSideNavExpanded = signal(false);

  constructor(
    //the underscore injections should prevent treeshaking so that the services would never be instanciated
    _azureMonitoringService: AzureSalaryMonitoringService,
    _chunkLoadErrorHandlerService: ChunkLoadErrorHandlerService,
    settingsFacade: SettingsFacade,
    appUpdateService: AppUpdateService,
    lohnkontextFacade: LohnkontextFacade,
  ) {
    lohnkontextFacade.getLohnkontextFromSettingsRequest();
    if (!isBrowserSupported()) {
      this.showBrowserNotSupportedMessage();
    }

    if (window['Cypress']) {
      const dateNow = window['dateNow'];
      if (dateNow != null) {
        Settings.now = () => dateNow;
      }
    }
    Settings.defaultZone = 'Europe/Berlin';
    Settings.defaultLocale = 'de-DE';
    Settings.defaultOutputCalendar = 'gregory';
    settingsFacade.initializeSettings();
    LicenseManager.setLicenseKey(this.agGridLicenseKey);

    ModuleRegistry.registerModules([
      ClientSideRowModelModule,
      CsvExportModule,
      ClipboardModule,
      MenuModule,
      RowGroupingModule,
      ServerSideRowModelModule,
    ]);

    this.initializeDarkMode();
    this.startLoadingPage();
    appUpdateService.checkForUpdates();
  }

  private startLoadingPage() {
    this.loadingPageService.show();
    this.loadingPageService.loadingDone$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.loadingState.set('enter'));
  }

  private initializeDarkMode() {
    const bodyElement = document.getElementsByTagName('body')[0];
    this.appSettingsFacade.select
      .setting('darkMode')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((darkMode) => {
        if (darkMode) {
          this.renderer2.addClass(bodyElement, 'darkMode');
          this.renderer2.removeClass(bodyElement, 'lightMode');
        } else {
          this.renderer2.addClass(bodyElement, 'lightMode');
          this.renderer2.removeClass(bodyElement, 'darkMode');
        }
      });
  }

  protected prepareRoute(outlet: RouterOutlet) {
    if (this.outletComponentChanged === true) {
      if (outlet.activatedRouteData.animate === 'list') {
        this.routingAnimationState =
          this.routingAnimationState === 'list1' ? 'list2' : 'list1';
      } else if (outlet.activatedRouteData.animate === 'detail') {
        this.routingAnimationState =
          this.routingAnimationState === 'detail1' ? 'detail2' : 'detail1';
      } else {
        this.routingAnimationState = 'void';
      }
      this.outletComponentChanged = false;
    }
    return this.routingAnimationState;
  }

  protected onActivate() {
    this.outletComponentChanged = true;
  }

  protected startOutletAnimation() {
    this.animationService.outletAnimationRunning$.next(true);
  }

  protected endOutletAnimation() {
    this.animationService.outletAnimationRunning$.next(false);
  }

  private showBrowserNotSupportedMessage() {
    this.dialogService.openDialog({
      title: 'Achtung',
      message: `Es wurde ein nicht unterstützter Browser erkannt.<br>
      Bei Verwendung eines nicht unterstützten Browsers kann es zu Darstellungs- sowie Funktionsfehlern kommen,
      die die Nutzung der Anwendung erschweren oder teilweise unmöglich machen.<br><br>
      Es wird dringend empfohlen einen der folgenden Browser in aktueller Version zu verwenden:<br>
      - Google Chrome<br>
      - Microsoft Edge<br><br>
      Supportanfragen bei Verwendung eines nicht unterstützten Browsers werden nicht übernommen.`,
      button1Caption: 'Verstanden',
      maxWidth: 750,
    });
  }
}
