import { registerLocaleData } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  inject,
  isDevMode,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import {
  EnvironmentConfigService,
  JsonDateInterceptor,
  PendingRequestsInterceptor,
  TrimInterceptor,
} from '@salary/common/api/base-http-service';
import { CommonAuthenticationModule } from '@salary/common/authentication';
import { CommonAuthorizationModule } from '@salary/common/authorization';
import {
  CREATE_ROUTE_CONFIG,
  CommonComponentsModule,
} from '@salary/common/component';
import { CommonDumbComponentsModule } from '@salary/common/dumb-components';
import { buildParameterSettings } from '../build-parameter-settings/build-parameter-settings';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import {
  AzureSalaryMonitoringService,
  ChunkLoadErrorHandlerService,
} from './services';
registerLocaleData(localeDe, 'de-DE');
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    CommonDumbComponentsModule.forRoot(),
    CommonComponentsModule.forRoot(),
    AppRoutingModule,
    CommonAuthenticationModule,
    CommonAuthorizationModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: buildParameterSettings.serviceWoker,
    }),
  ],
  providers: [
    provideExperimentalZonelessChangeDetection(),
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    AzureSalaryMonitoringService,
    ChunkLoadErrorHandlerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TrimInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonDateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PendingRequestsInterceptor,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    if (isDevMode()) {
      console.log(inject(EnvironmentConfigService));
    }
    CREATE_ROUTE_CONFIG.validationModuleLoader = () =>
      import('@salary/common/extras').then(
        (module) => module.CommonExtrasModule,
      );
  }
}
