import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { EnvironmentConfigService } from '@salary/common/api/base-http-service';
import { AppModule } from './app/app.module';
import { buildParameterSettings } from './build-parameter-settings/build-parameter-settings';

function deriveStageNameFromUrl() {
  const hostname = window?.location?.hostname;
  if (!hostname) return undefined;
  if (hostname.includes('localhost')) return 'dev';
  return undefined;
}

if (buildParameterSettings.production) {
  enableProdMode();
}

const stageName = deriveStageNameFromUrl();
const configFileName = `config${stageName ? '.' + stageName : ''}.json`;
fetch('/assets/config/' + configFileName)
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  })
  .then((json) => {
    if (
      !json.apiAdministrationUrl ||
      !json.apiMasterdataUrl ||
      !json.apiCalculationUrl ||
      !json.apiTransactionsUrl ||
      !json.apiReportingUrl ||
      !json.apiConsolidationUrl ||
      !json.azureB2C
    ) {
      throw new Error(`Invalid ${configFileName}: ${JSON.stringify(json)}`);
    }
    return Object.assign(new EnvironmentConfigService(), json);
  })
  .then((configService) => {
    platformBrowserDynamic([
      {
        provide: EnvironmentConfigService,
        useValue: configService,
      },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  })
  .catch(
    (err) =>
      (document.body.innerHTML = `<h1>Fehler beim Laden der ${configFileName}</h1> <p style="color: red">${err.message}</p>`),
  );
