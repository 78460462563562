import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { NavigationService } from '@salary/common/component';
import { buildParameterSettings } from '../build-parameter-settings/build-parameter-settings';
import { PreloadingService } from './services';

const routes: Route[] = [
  {
    path: '',
    redirectTo: 'administration',
    pathMatch: 'full',
  },
  {
    path: 'administration',
    loadChildren: () =>
      import('@salary/administration/component').then(
        (module) => module.AdministrationComponentsModule,
      ),
  },
  {
    path: 'masterdata',
    loadChildren: () =>
      import('@salary/masterdata/component').then(
        (module) => module.MasterdataComponentsModule,
      ),
  },
  {
    path: 'calculation',
    loadChildren: () =>
      import('@salary/calculation/component').then(
        (module) => module.CalculationComponentsModule,
      ),
  },
  {
    path: 'consolidation',
    loadChildren: () =>
      import('@salary/consolidation/component').then(
        (module) => module.ConsolidationComponentsModule,
      ),
  },
  {
    path: 'reporting',
    loadChildren: () =>
      import('@salary/reporting/component').then(
        (module) => module.ReportingComponentsModule,
      ),
  },
  {
    path: 'transaction',
    loadChildren: () =>
      import('@salary/transaction/component').then(
        (module) => module.TransactionComponentsModule,
      ),
  },
  {
    path: 'extras',
    loadChildren: () =>
      import('@salary/common/extras').then(
        (module) => module.CommonExtrasModule,
      ),
    outlet: 'sidebar',
  },
  ...buildParameterSettings.e2eRoutes,
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadingService,
      bindToComponentInputs: true,
    }),
  ],
})
export class AppRoutingModule {
  constructor(
    navigationService: NavigationService,
    preloadingService: PreloadingService,
  ) {
    navigationService.addNodes([
      {
        text: 'Administration',
        path: 'administration/lizenznehmer',
        preloadModules: () => preloadingService.preloadAdministration(),
        icon: 'security',
        availableWithoutAbrechnungskreisLohnkontext: true,
      },
      {
        text: 'Rechenzentrum',
        path: 'administration/importe',
        preloadModules: () => {
          preloadingService.preloadAdministration();
          preloadingService.preloadConsolidation();
        },
        icon: 'dns',
        availableWithoutAbrechnungskreisLohnkontext: true,
      },
      {
        text: 'Betriebsdaten',
        path: 'administration/verbuchungsbetriebe',
        preloadModules: () => preloadingService.preloadAdministration(),
        icon: 'assured_workload',
        availableWithoutAbrechnungskreisLohnkontext: true,
      },
      {
        text: 'Stammdaten',
        path: 'masterdata/personal',
        preloadModules: () => preloadingService.preloadMasterdata(),
        icon: 'store',
      },
      {
        text: 'Zusatzstämme',
        path: 'masterdata/berufsgruppen',
        preloadModules: () => preloadingService.preloadMasterdata(),
        icon: 'add_business',
      },
      {
        text: 'Bewegungsdaten',
        path: 'transaction/nachpersonal',
        preloadModules: () => preloadingService.preloadTransaction(),
        icon: 'autorenew',
      },
      {
        text: 'Abrechnung',
        path: 'calculation/schaetzungen',
        preloadModules: () => preloadingService.preloadCalculation(),
        icon: 'spellcheck',
      },
      {
        text: 'Ermittlung und Übermittlung',
        path: 'consolidation/deuev',
        preloadModules: () => preloadingService.preloadConsolidation(),
        icon: 'watch_later',
      },
      {
        text: 'Auswertungen',
        path: 'reporting/lohn',
        preloadModules: () => preloadingService.preloadReporting(),
        icon: 'assessment',
      },
    ]);
  }
}
