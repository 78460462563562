import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { SalaryPreloadingStrategy } from '@salary/common/utils';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PreloadingService
  implements PreloadingStrategy, SalaryPreloadingStrategy
{
  private masterdataPreloading: () => Observable<unknown>;
  private calculationPreloading: () => Observable<unknown>;
  private consolidationPreloading: () => Observable<unknown>;
  private reportingPreloading: () => Observable<unknown>;
  private transactionPreloading: () => Observable<unknown>;
  private administrationPreloading: () => Observable<unknown>;

  private preloadedMasterdata = false;
  private preloadedCalculation = false;
  private preloadedConsolidation = false;
  private preloadedReporting = false;
  private preloadedTransaction = false;
  private preloadedAdministration = false;

  preload(route: Route, load: () => Observable<unknown>): Observable<unknown> {
    if (route.path === 'masterdata') {
      this.masterdataPreloading = load;
    } else if (route.path === 'calculation') {
      this.calculationPreloading = load;
    } else if (route.path === 'consolidation') {
      this.consolidationPreloading = load;
    } else if (route.path === 'reporting') {
      this.reportingPreloading = load;
    } else if (route.path === 'transaction') {
      this.transactionPreloading = load;
    } else if (route.path === 'administration') {
      this.administrationPreloading = load;
    }
    return of(null);
  }

  preloadAll(): void {
    this.preloadMasterdata();
    this.preloadCalculation();
    this.preloadConsolidation();
    this.preloadReporting();
    this.preloadTransaction();
    this.preloadAdministration();
  }

  preloadMasterdata() {
    if (!this.preloadedMasterdata && this.masterdataPreloading != null) {
      this.preloadedMasterdata = true;
      this.masterdataPreloading().subscribe();
    }
  }

  preloadCalculation() {
    if (!this.preloadedCalculation && this.calculationPreloading != null) {
      this.preloadedCalculation = true;
      this.calculationPreloading().subscribe();
    }
  }

  preloadConsolidation() {
    if (!this.preloadedConsolidation && this.consolidationPreloading != null) {
      this.preloadedConsolidation = true;
      this.consolidationPreloading().subscribe();
    }
  }

  preloadReporting() {
    if (!this.preloadedReporting && this.reportingPreloading != null) {
      this.preloadedReporting = true;
      this.reportingPreloading().subscribe();
    }
  }

  preloadTransaction() {
    if (!this.preloadedTransaction && this.transactionPreloading != null) {
      this.preloadedTransaction = true;
      this.transactionPreloading().subscribe();
    }
  }

  preloadAdministration() {
    if (
      !this.preloadedAdministration &&
      this.administrationPreloading != null
    ) {
      this.preloadedAdministration = true;
      this.administrationPreloading().subscribe();
    }
  }
}
