<salary-layout
  [@loadingTrigger]="loadingState()"
  (leftPanelOverlayModeChanged)="disableExpandCollapse.set($event)"
>
  <salary-layout-left [isExpanded]="isLeftSideNavExpanded()">
    <salary-navigation-menu
      [disableExpandCollapse]="disableExpandCollapse()"
      (expandedChanged)="isLeftSideNavExpanded.set($event)"
    >
      <salary-current-date-label />
    </salary-navigation-menu>
  </salary-layout-left>
  <salary-layout-content>
    <div
      style="height: 100%; position: relative"
      [@outletTrigger]="prepareRoute(outlet)"
      (@outletTrigger.start)="startOutletAnimation()"
      (@outletTrigger.done)="endOutletAnimation()"
    >
      <router-outlet (activate)="onActivate()" #outlet="outlet" />
    </div>
  </salary-layout-content>
  <salary-layout-right>
    <router-outlet name="sidebar" />
  </salary-layout-right>
</salary-layout>
