import { Route } from '@angular/router';

export const buildParameterSettings: {
  production: boolean;
  e2eRoutes: Route[];
  serviceWoker: boolean;
} = {
  production: true,
  e2eRoutes: [],
  serviceWoker: true,
};
